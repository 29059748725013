




import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "Avatar",
  props: {
    size: { type: String, default: "is-48x48" },
    url: String,
    grup: String,
    gender: String,
  },
  setup(props) {
    const imageUrl = ref(props.url);
    if (!imageUrl.value) {
      // get random image... tidak bisa dijadikan function, karena
      // function require harus langsung dikasih string.
      // pilihannya: dr_laki[#].png, dr_perempuan[#].png, dr_netral[#].png
      // laki[#].png, perempuan[#].png, netral[#].png
      // let gender = "netral";
      let gender = "laki";
      let imageFile = gender;
      if (props.gender && ["Laki-Laki", "Perempuan"].includes(props.gender)) {
        gender = props.gender === "Laki-Laki" ? "laki" : "perempuan";
      }
      if (props.grup && ["konsulen", "residen"].includes(props.grup)) {
        imageFile = `dr_${gender}`;
      }
      const randInt = Math.floor(Math.random() * 30) + 1; // TODO: Jangan di-hardcode 30
      // Akan diganti menggunakan image dari AWS....
      imageUrl.value = require(`@/apps/core/assets/img/avatar/${imageFile}/${imageFile}${randInt}.png`);
    }

    // watch ketika props.value berubah (selesai loading fetch)
    watch(
      () => props.url,
      (newValue) => {
        if (imageUrl.value !== newValue) {
          // Catatan: Harus pakai trick ini, di-set undefined (dan ada v-if)
          // kemudian delay dan di-set src url-nya, agar perubahan bisa
          // ter-render.
          imageUrl.value = undefined;
          setTimeout(() => (imageUrl.value = newValue), 100);
        }
      }
    );

    return { imageUrl };
  },
});
